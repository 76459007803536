<template>
  <b-modal
    id="request-heaven"
    name="request-heaven"
    size="md"
    :title="$t('views.seller.login.text_1568')"
  >
    <b-row>
      <b-col v-if="requestHeaven === 0" cols="12" class="mb-3">
        {{ `${$t('views.seller.login.text_1569')} ${isHeaven ? 'Heaven' : 'Greenn'}, ${$t('views.seller.login.text_1576')}` }}
      </b-col>
      <b-col v-else cols="12" class="mb-3">
        {{ $t('views.seller.login.text_1570') }}
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <div>
          <BaseButton variant="primary" class="mr-4" @click="redirectGreenn()">
            {{ $t('views.seller.login.text_1571') }}
          </BaseButton>
        </div>
        <div>
          <BaseButton v-if="requestHeaven === 0" variant="info" @click="onSubmit">
            {{ $t('views.seller.login.text_1572') }}
          </BaseButton>
          <BaseButton v-else variant="info" @click="cancel">
            OK
          </BaseButton>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import RequestAccess from '@/services/resources/RequestAccessService.js'
const serviceRequestAccess = RequestAccess.build()

export default {
  name: 'ModalHeaven',
  props:{
    requestHeaven: {
      required: true,
      type: Number,
      default: 0
    },
    userId: {
      required: true,
      type: Number,
      default: 0
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    onSubmit() {
      serviceRequestAccess.update({system: 'heaven'}, this.userId)
        .then(res => {
          if(res) {
            this.$bvToast.toast(this.$t('views.seller.login.text_1573'), {
              title: this.$t('views.seller.login.text_1574'),
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: true
            })
          }
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('views.seller.login.text_1575'), {
              title: this.$t('views.seller.login.text_1574'),
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: true
            })
        })
      this.$bvModal.hide("request-heaven")
    },
    redirectGreenn() {
      window.location.href = process.env.VUE_ADM_PAGE
    }
  }
}
</script>
